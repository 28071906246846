import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompBlockCols from "../../components/MainStats/CompBlockCols"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
// import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'

class RonaldoMadridStats extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    return (

      <Layout>
        <SEO
          title={"Cristiano Ronaldo Real Madrid Goals and Stats"}
          description={`Cristiano Ronaldo goals, assists, hat-tricks, free kicks and other stats for Real Madrid in La Liga, the Champions League, and all other competitions.`}
          canonicalPath={`/all-time-stats/ronaldo-real-madrid-stats/`}
        />

        <h1>
          Ronaldo Real Madrid Goals and Stats
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'Al Nassr Stats', 'url': '/all-time-stats/ronaldo-al-nassr-stats/' },
            { 'name': 'Juventus Stats', 'url': '/all-time-stats/ronaldo-juventus-stats/' },
            { 'name': 'Real Madrid Stats', 'url': 'none', 'active': true },
            { 'name': 'Man Utd Stats', 'url': '/all-time-stats/ronaldo-manchester-united-stats/' },
            { 'name': 'Sporting CP Stats', 'url': '/all-time-stats/ronaldo-sporting-cp-stats/' }
          ]}
        />

        <CompBlockCols>

          <CompetitionBlock competition="All Time Real Madrid" team="Real Madrid" mdata={mdata} rdata={rdata} perfmsg="[comps] only" />

          <CompetitionBlock competition="La Liga - Real Madrid" compTitle="La Liga" team="Real Madrid" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="Champions League - Real Madrid" compTitle="Champions League" team="Real Madrid" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="Copa del Rey - Real Madrid" compTitle="Copa del Rey" team="Real Madrid" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="Supercopa de Espana - Real Madrid" compTitle="Supercopa de Espana" team="Real Madrid" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="UEFA Super Cup - Real Madrid" compTitle="UEFA Super Cup" team="Real Madrid" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="Club World Cup - Real Madrid" compTitle="Club World Cup" team="Real Madrid" mdata={mdata} rdata={rdata} />

        </CompBlockCols>

        
        {/* <CopyBlock>
          <h2>Messi & Ronaldo in Games vs Each Other</h2>

          <InfoBar />

          

          <p>If you’re looking for more Messi vs Ronaldo stats, follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, where we consistenly post exclusive stats.</p>

        </CopyBlock> */}

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/real-madrid/"}}) {
      edges {
        node {
          id
          competition
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/real-madrid/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
  }
`

export default RonaldoMadridStats
